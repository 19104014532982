.invoicePaidCardHeader{
    background: var(--bobex-blue)!important;
    color: white;
}
.invoiceToPayCardHeader{
    background: var(--cta-orange)!important;
    color: white;
}


.file-icons {
    font-size: 2rem;
    color: var(--bobex-blue);
    cursor: pointer;
}

.invoices-table tbody th:not(:first-child) {
    color: unset;
    font-weight: 400;
}

.icon-container.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.ogone-button {
    box-shadow: unset !important;
    color: white !important;
    border: 1px solid var(--cta-orange) !important;
}

.ogone-button:hover {
    background-color: white;
    color: var(--cta-orange) !important;
    border: 1px solid var(--cta-orange) !important;
}


@media (max-width: 1024px) {
    .file-icons {
        font-size: 1.5rem;
    }
}