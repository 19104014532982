.bobex-app .header .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--icon-blue);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}

.bobex-app .header .icon-wrapper:hover {
    background-color: var(--very-light-blue) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}

@media (max-width: 576px) {
    .bobex-app .header .icon-wrapper {
        margin: 0.5rem;
    }
}

.search-lead-input-wrapper {
    height: 37px;
}

.search-lead-input-wrapper button > svg {
    width: 16px;
}

.bobex-app .responsive-header * {
    margin: unset;
    padding: unset;
}

.bobex-app .responsive-toggler {
    color: var(--white);
}
