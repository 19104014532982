@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://www.bobex.com/bootstrap/back3/vendors/font-awesome5/css/all.min.css');
@import url('https://www.bobex.com/bootstrap/back3/vendors/font-awesome5/css/all.min.css');

/* HTML Elements formatting*/


body {
    overflow: hidden;
}

body:not(.star-rating) {
    font-family: 'Roboto', sans-serif;
    color: var(--text-color);
    text-align: left !important;
}

p {
    font-family: 'Roboto', sans-serif !important;
    font-size: 1rem;
    color: var(--text-color);
    font-weight: 400;
    margin:0;
}

h1 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold !important;
    font-size: 1.8rem !important;
    color: var(--bobex-dark-blue) !important;
}

h2 {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    color: var(--bobex-dark-blue) !important;
    font-family: 'Montserrat', sans-serif !important;
}

h3 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    color: var(--bobex-blue) !important;
}

a {
    text-decoration: none !important;
    color: var(--bobex-blue) ;
}

.breadcrumb a:visited {
    color: var(--bobex-blue) !important;
}

.contact-wrapper a {
    color: var(--sidebar-text) !important;
}

.bg-white {
    background-color: var(--white);
}

.bg-blue {
    background-color: var(--light-blue);
}



.cta-light-blue {
    background-color: var(--icon-blue) !important;
    border-radius: 3rem;
    border-color: var(--icon-blue);
    color: var(--white);
    font-size: 1rem !important;
}

.cta-light-blue:hover,
.cta-light-blue:focus,
.cta-light-blue:active{
    background-color: #3BA2CEFF !important;
    border-radius: 3rem;
    border-color: var(--icon-blue) !important;
    color: var(--white) !important;
    font-size: 1rem !important;
}

.link {
    color: var(--link-orange) !important;
    cursor: pointer !important;
}

.link-blue {
    color: var(--bobex-blue) !important;
    cursor: pointer !important;
}

.full-width {
    width: 100%;
}

.border-blue {
    border: 2px solid var(--border-blue) !important;
    border-radius: unset;
}

.border-orange {
    border: 1px solid var(--cta-orange) !important;
}

.border-bobex-blue {
    border: 1px solid var(--bobex-blue) !important;
}

.hr {
    border-bottom: 3px solid #d4ebf5
}


@media (max-width: 576px) {
    h1 {
        font-size: 1.2rem !important;
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
        line-height: unset;
    }

    h2 {
        font-size: 1rem !important;
        margin-bottom: 1rem !important;
    }
}

/*custom bootstrap-like classes*/

.w-5 {
    width: 5% !important;
}

.w-10 {
    width: 10% !important;
}

.w-20 {
    width: 20% !important;
}

.w-30 {
    width: 30% !important;
}

.w-40 {
    width: 40% !important;
}

.w-60 {
    width: 60% !important;
}

.w-70 {
    width: 70% !important;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}

.bold {
    font-weight: bold !important;
}

.thin {
    font-weight: 300 !important;
}

.text-blue {
    color: var(--bobex-blue) !important;
}

.text-white > p {
    color: var(--white) !important;
}

.text-light-blue, .text-light-blue > p {
    color: var(--light-blue) !important;
}

.text-xl {
    font-size: 1.5rem !important;
}
.text-l{
    font-size: 1.2rem !important;
}

.text-s, .text-xs > p {
    font-size: 0.6rem !important;
}
.vh-50{
    height: 50vh !important;
}
.cursor-pointer{
    cursor: pointer !important;
}


.searchInputsContainer{
    border-radius: 2rem !important;
    background: white;
    padding: 0.5rem;
    width:30%;
}

.affiliate_select_input{
    border-radius: 2rem !important;
    padding: 0.5rem 1rem;
    margin-bottom:10px;
    margin-left: 10px;
    border: none !important;
}

.affiliate_inputs_labels{
    font-size: 1.2em !important;
    font-weight: 400 !important;
    color: var(--bobex-blue);
}

.border-red{
    border: 2px solid #f87479 !important;
}

.bobex-card{
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    overflow: hidden;
}

@media (max-width: 1024px) {
    .searchInputsContainer {
        width: 100% !important;
    }
}

/* End of HTML Elements formatting*/