
/* Main App Structure Blocks*/
.bobex-app {
    display: flex;
    flex-direction: row;
}

.bobex-app-content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: unset !important;
    padding: unset !important;
    background-color: var(--very-light-blue);
    overflow-x: hidden;
}

.bobex-app-content {
    height: 100vh;
    flex-grow: 1;
    overflow-x: hidden;
}

@media (min-width: 375px) {
    .bobex-app-content, .bobex-app-content-wrapper {
        max-width: 100% !important;
    }
}

@media(max-width: 576px){
    .bobex-app-content-wrapper {
       padding-bottom : 4rem !important;
    }
}
/* End of Main App Structure Blocks*/

