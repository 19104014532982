* {
    margin: 0;
    padding: 0;
}

.login-card {
    width: 500px;
    z-index: 101;
}
.loginForm {
    width: 80% !important;
}

@media only screen and (max-width: 678px) {
    .login-card {
        width: 75%;
    }

    .loginForm {
        width: 100% !important;
    }
}

.teamLink {
    cursor: pointer;
    font-size: 0.8rem !important;
    margin: 0 auto;
    padding: 0;

}

.teamLink:hover {
    text-decoration: underline;
}

.login-page section {
    position: relative;
    width: 100%;
    height: 100vh;
    background: #d4ebf5;
    overflow: hidden;
}

.login-page section .air {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(https://1.bp.blogspot.com/-xQUc-TovqDk/XdxogmMqIRI/AAAAAAAACvI/AizpnE509UMGBcTiLJ58BC6iViPYGYQfQCLcBGAsYHQ/s1600/wave.png);
    background-size: 1000px 100px
}

.login-page section .air.air1 {
    animation: wave 30s linear infinite;
    z-index: 100;
    opacity: 1;
    animation-delay: 0s;
    bottom: 0;
}

.login-page section .air.air2 {
    animation: wave2 25s linear infinite;
    z-index: 99;
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 10px;
}

.login-page section .air.air3 {
    animation: wave 30s linear infinite;
    z-index: 98;
    opacity: 0.2;
    animation-delay: -2s;
    bottom: 15px;
}

.login-page section .air.air4 {
    animation: wave2 10s linear infinite;
    z-index: 97;
    opacity: 0.7;
    animation-delay: -5s;
    bottom: 20px;
}

@keyframes wave {
    0% {
        background-position-x: 0px;
    }
    100% {
        background-position-x: 1000px;
    }
}

@keyframes wave2 {
    0% {
        background-position-x: 0px;
    }
    100% {
        background-position-x: -1000px;
    }
}

